<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <h1>Partners</h1>
        <nav class="breadcrumbs">
          <a href="/">Home</a>/
          <span class="current">Partners</span>
        </nav>
      </div>

      <div class="header-aside">
        <search />
        <router-link class="button secondary" :to="{ name: 'addPartner' }">Add Partner</router-link>
      </div>
    </div>
    <div class="search-container">
      <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search Partner Name" />
      <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
    </div>
    <data-table
      :loading="$apollo.loading"
      table-id="partnersTable"
      :headers="partnerHeaders"
      :entries="filteredPartners"
      :pagination-total-count="totalCount"
      :pagination-per-page="perPage"
      :pagination-current-page="currentPage"
      @onPaginate="onPaginate($event)"
    >
      <template #name="{ item }">
        <a @click="openFlyout(item)">{{ name(item) }}</a>
      </template>
      <template #phone="{ item }"
        ><p>{{ item.phone | formatPhone }}</p></template
      >
      <template #email="{ item }">
        <a :href="`mailto:${item.email}`">{{ item.email }}</a>
      </template>
      <template #numLocations="{ item }">{{ item.locations.length }}</template>
    </data-table>
    <partner-flyout :partner="fullPartner" />
  </fragment>
</template>

<script>
import Search from "@/components/search/Search";
import { Fragment } from "vue-fragment";
import { GET_PARTNERS } from "@/modules/admin/partners/graph/subscriptions";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import PartnerFlyout from "@/modules/admin/onboarding/flyouts/PartnerFlyout";
import { QUERY_FIND_PARTNER } from "@/modules/admin/onboarding/graph/subscriptions";
import { mapActions } from "vuex";
import { DateTime, Interval } from "luxon";
export default {
  name: "PartnersPage",
  title: "Partners",
  components: { PartnerFlyout, DataTable, Search, Fragment },
  data: function () {
    return {
      searchTerm: "",
      partners: [],
      search: "",
      totalCount: 0,
      currentPage: 0,
      perPage: 50,
      offset: 0,
      selectedPartner: "",
      partner: {},
      fullPartner: {},
    };
  },
  computed: {
    partnerCount() {
      return this.partners ? this.partners.length : 0;
    },
    filteredPartners() {
      return this.partners;
    },
    partnerHeaders() {
      return [
        new TableHeader({ name: "name", cellContents: "name", label: "Name" }),
        new TableHeader({ name: "phone", cellContents: "phone", label: "Phone" }),
        new TableHeader({ name: "email", cellContents: "email", label: "Email" }),
        new TableHeader({ name: "numLocations", cellContents: "numLocations", label: "Num of Locations", sortable: true }),
      ];
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    openFlyout(partner) {
      this.selectedPartner = partner.id;
      this.showFlyout("partner-flyout");
    },
    resetSearch() {
      this.searchTerm = "";
      this.currentPage = 1;
    },
    searchIcon() {
      return this.searchTerm !== "";
    },
    name(partner) {
      return partner.firstName ? `${partner.firstName} ${partner.lastName}`.trim() : "No name given";
    },
    onPaginate(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
    },
    sentenceCase(string) {
      if (string === "NEWLY_APPROVED") {
        return "Newly Approved";
      }
      let removeCharacters = string.replaceAll("_", " ");
      return removeCharacters.charAt(0) + removeCharacters.slice(1).toLowerCase();
    },
    setMaintenanceAlerts(partner) {
      let insuranceExpiry = new Date(partner.partner.insuranceExpiry);
      let driverLicenceExpiry = new Date(partner.partner.driversLicenses[0]?.expiration);
      let today = DateTime.now();
      let insuranceExpirayDifference = Interval.fromDateTimes(today, insuranceExpiry).length("days");
      let driversLicenceExpirayDifference = Interval.fromDateTimes(today, driverLicenceExpiry).length("days");
      if (Math.round(insuranceExpirayDifference) <= 14 || Math.round(driversLicenceExpirayDifference) <= 14) {
        if (Math.round(insuranceExpirayDifference) <= 14 && Math.round(driversLicenceExpirayDifference) <= 14) {
          if (insuranceExpiry > driverLicenceExpiry) {
            this.alertDate = insuranceExpiry.toISOString();
            return "Verify Auto Insurance(1 more)";
          } else {
            this.alertDate = driverLicenceExpiry.toISOString();
            return "Drivers License Expiring(1 more)";
          }
        } else {
          if (insuranceExpiry > driverLicenceExpiry) {
            this.alertDate = driverLicenceExpiry.toISOString();
            if (isNaN(driversLicenceExpirayDifference)) {
              return "Expired Drivers License";
            }
            return "Drivers License Expiring";
          } else {
            this.alertDate = insuranceExpiry.toISOString();
            if (isNaN(insuranceExpirayDifference)) {
              return "Verify Auto Insurance update";
            }
            return "Verify Auto Insurance";
          }
        }
      } else if (isNaN(insuranceExpirayDifference) || isNaN(driversLicenceExpirayDifference)) {
        if (isNaN(insuranceExpirayDifference) && isNaN(driversLicenceExpirayDifference)) {
          if (insuranceExpiry > driverLicenceExpiry) {
            this.alertDate = driverLicenceExpiry.toISOString();
            return "Expired Drivers License(1 more)";
          } else {
            this.alertDate = insuranceExpiry.toISOString();
            return "Verify Auto Insurance update(1 more)";
          }
        }
        if (insuranceExpiry > driverLicenceExpiry) {
          this.alertDate = driverLicenceExpiry.toISOString();
          return "Expired Drivers License";
        } else {
          this.alertDate = insuranceExpiry.toISOString();
          return "Verify Auto Insurance update";
        }
      } else {
        return "None";
      }
    },
    alertType(alertMessage) {
      switch (alertMessage) {
        case "Verify Auto Insurance(1 more)":
          return "warning";
        case "Drivers License Expiring(1 more)":
          return "warning";
        case "Drivers License Expiring":
          return "warning";
        case "Verify Auto Insurance":
          return "warning";
        case "Expired Drivers License":
          return "alert";
        case "Verify Auto Insurance update":
          return "alert";
        case "Expired Drivers License(1 more)":
          return "alert";
        case "Verify Auto Insurance update(1 more)":
          return "alert";
        case "None":
          return "none";
      }
    },
  },
  apollo: {
    partners: {
      fetchPolicy: "network-only",
      query: GET_PARTNERS,
      debounce: 500,
      variables() {
        return {
          fullName: `%${this.searchTerm}%`,
          offset: this.offset,
        };
      },
      result({ data }) {
        this.totalCount = data?.partners_aggregate?.aggregate?.count ? data.partners_aggregate.aggregate.count : this.totalCount;
        this.partners = data?.partners ? data.partners : [];
      },
    },
    partner: {
      query: QUERY_FIND_PARTNER,
      variables() {
        return {
          partnerID: this.selectedPartner,
        };
      },
      result({ data }) {
        const partner = data.partner[0];
        this.fullPartner = {
          id: partner.id,
          notes: partner.notes,
          name: `${partner.partner.firstName} ${partner.partner.lastName}`.trim(),
          email: partner.partner.email,
          phone: partner.partner.phone ? partner.partner.phone : "",
          locations: [partner.location],
          locationID: partner?.location?.id ? partner.location.id : null,
          partnerID: partner.partner.id,
          partnerStatus: this.sentenceCase(partner.status),
          approvalDate: partner?.approvalDate ? partner.approvalDate : "",
          userID: partner?.partner?.userID ? partner.partner.userID : "",
          externalID: partner.partner.ids[0]?.externalID ? partner.partner.ids[0].externalID : null,
          lastPay: partner?.partner?.payouts[0]?.endDate ? partner.partner.payouts[0].endDate : "",
          insuranceExpiry: partner?.partner?.insuranceExpiry ? partner.partner.insuranceExpiry : "",
          driversLicense: partner?.partner?.driversLicenses[0] ? partner.partner.driversLicenses : "",
          maintenanceAlert: status === "INACTIVE" ? "None" : this.setMaintenanceAlerts(partner),
          alertDate: this.setMaintenanceAlerts(partner) === "None" || status === "INACTIVE" ? "" : this.alertDate,
          alertType: status === "INACTIVE" ? "" : this.alertType(this.setMaintenanceAlerts(partner)),
        };
      },
      skip() {
        return !this.selectedPartner;
      },
    },
  },
};
</script>
