var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"header-contents"},[_c('h1',[_vm._v("Partners")]),_c('nav',{staticClass:"breadcrumbs"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")]),_vm._v("/ "),_c('span',{staticClass:"current"},[_vm._v("Partners")])])]),_c('div',{staticClass:"header-aside"},[_c('search'),_c('router-link',{staticClass:"button secondary",attrs:{"to":{ name: 'addPartner' }}},[_vm._v("Add Partner")])],1)]),_c('div',{staticClass:"search-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"deposit-search",attrs:{"type":"search","placeholder":"Search Partner Name"},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}}),_c('button',{attrs:{"type":"submit"}},[(_vm.searchIcon())?_c('span',{staticClass:"icon-x deposit-icon",on:{"click":_vm.resetSearch}}):_c('span',{staticClass:"icon-search deposit-icon"})])]),_c('data-table',{attrs:{"loading":_vm.$apollo.loading,"table-id":"partnersTable","headers":_vm.partnerHeaders,"entries":_vm.filteredPartners,"pagination-total-count":_vm.totalCount,"pagination-per-page":_vm.perPage,"pagination-current-page":_vm.currentPage},on:{"onPaginate":function($event){return _vm.onPaginate($event)}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openFlyout(item)}}},[_vm._v(_vm._s(_vm.name(item)))])]}},{key:"phone",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm._f("formatPhone")(item.phone)))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"numLocations",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.locations.length))]}}])}),_c('partner-flyout',{attrs:{"partner":_vm.fullPartner}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }