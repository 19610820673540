import gql from "graphql-tag";

export const GET_ONBOARDING_SPECIALISTS = gql`
  query getOnboardingSpecialists {
    employees(where: { user: { role: { _in: [ONBOARDING_SPECIALIST, ACCOUNT_MANAGER] } } }) {
      id
      firstName
      lastName
      user {
        id
        role
      }
    }
  }
`;

export const FIND_LOCATIONS_FOR_USER = gql`
subscription findLocationsForUser($userID: uuid!) {
  clientLocations(where: {_or: [{teamSpecialist: {employee: {user: {id: {_eq: $userID}}}}}, {team: {accountManager: {user: {id: {_eq: $userID}}}}}], deleted: {_eq: false}}, order_by: {name: asc}) {
    id
    name
  }
}

`;
export const QUERY_FIND_LOCATIONS_FOR_USER = gql`
query findLocationsForUser($userID: uuid!) {
  clientLocations(where: {_or: [{teamSpecialist: {employee: {user: {id: {_eq: $userID}}}}}, {team: {accountManager: {user: {id: {_eq: $userID}}}}}], deleted: {_eq: false}}, order_by: {name: asc}) {
    id
    name
  }
}
`;

export const FIND_CANDIDATES_FOR_USER = gql`
  subscription findCandidatesForUser($userID: uuid!, $offset: Int, $limit: Int) {
    clientLocationPartners(
      where: { _or: [{ location: { teamSpecialist: { employee: { user: { id: { _eq: $userID } } } } } }, { location: { team: { accountManager: { user: { id: { _eq: $userID } } } } } }] }
      limit: $limit
      offset: $offset
    ) {
      id
      partner {
        id
        firstName
        lastName
        email
        phone
        ids(where: { idType: { _eq: CHECKR } }) {
          id
          idType
          externalID
          partnerID
        }
        user {
          id
          username
        }
      }
      location {
        id
        name
        teamSpecialist {
          employee {
            firstName
            lastName
            email
          }
        }
      }
      candidateProgress(order_by: { date: desc }, where: { _and: [{ candidateStatus: { _neq: "COMPLETE" } }, { candidateStep: { _neq: "DOCUMENTS" } }] }) {
        step {
          name
          description
          stage
          timeBasedAlerts {
            id
            status
            time
          }
        }
        candidateStatus
        candidateStatusDetail
        date
        alertTime
      }
    }
  }
`;
export const QUERY_FIND_CANDIDATES_FOR_USER_TOTAL_COUNT = gql`
  query findCandidatesForUserTotalCount($userID: uuid!, $location: [uuid!], $urgency: [String!], $fullName: String, $phone: String) {
    total: clientLocationPartners_aggregate(
      where: {
        _and: [
          { partner: { _or: [{ fullName: { _ilike: $fullName } }, { email: { _ilike: $fullName } }, { phone: { _eq: $phone } }] } }
          { _or: [{ location: { teamSpecialist: { employee: { user: { id: { _eq: $userID } } } } } }, { location: { team: { accountManager: { user: { id: { _eq: $userID } } } } } }] }
          { _and: { clientLocationID: { _in: $location }, candidateProgress: { _and: { candidateStatus: { _in: $urgency } } } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const SUBSCRIPTION_FIND_CANDIDATES_FOR_USER = gql`
  subscription findCandidatesForUser($userID: uuid!, $location: [uuid!], $urgency: [String!], $fullName: String, $phone: String, $limit: Int, $offset: Int) {
    candidates: clientLocationPartners(
      where: {
        _and: [
          { partner: { _or: [{ fullName: { _ilike: $fullName } }, { email: { _ilike: $fullName } }, { phone: { _eq: $phone } }] } }
          { _or: [{ location: { teamSpecialist: { employee: { user: { id: { _eq: $userID } } } } } }, { location: { team: { accountManager: { user: { id: { _eq: $userID } } } } } }] }
          { _and: { clientLocationID: { _in: $location }, candidateProgress: { _and: [{ candidate: { candidateProgress: { candidateStatus: { _in: $urgency } } } }] } } }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      approvalDate
      #      notes
      partner {
        id
        userID
        #        firstName
        #        lastName
        fullName
        email
        phone
        #        ids(where: { idType: { _eq: CHECKR } }) {
        #          id
        #          idType
        #          externalID
        #          partnerID
        #        }
        #        user {
        #          id
        #          username
        #        }
      }
      location {
        id
        name
        stageCounts {
          count
          stage
          weekly
        }
        #        headCountRequests {
        #          id
        #          count
        #          approved
        #          startDate
        #        }
        #        teamSpecialist {
        #          id
        #          employee {
        #            id
        #            firstName
        #            lastName
        #            email
        #          }
        #        }
        #        client {
        #          id
        #        }
      }
      candidateProgress(
        limit: 1
        order_by: { date: desc }
        where: {
          _and: [
            { candidateStatus: { _neq: "COMPLETE" } }
            { candidateStep: { _neq: "DOCUMENTS" } }
            { candidateStatus: { _in: $urgency } }
            # { step: { stage: {_in: $stages } } }
          ]
        }
      ) {
        step {
          name
          description
          stage
          timeBasedAlerts {
            id
            status
            time
          }
        }
        candidateStatus
        candidateStatusDetail
        date
        alertTime
      }
    }
  }
`;
export const ONBOARDING_CANDIDATE_VIEW = gql`
query OnboardingCandidateView(
  $locationIDs: [uuid!]
  $userID: uuid!
  $stages: [String!]
  $urgency: [String!]
  $limit: Int
  $offset: Int
  $fullName: String
  $phone: String
  $discordStatus: [String!]
  $paramargs:uuid!
) {
  onboardingCandidateView:f_onboardingcandidateview(
    args: {param: $paramargs}
    where: {
      _and: [
        { _or: [{ specialistID: { _eq: $userID } }, { departmentMangerID: { _eq: $userID } }, { accountManagerID: { _eq: $userID } }] }
        { _or: [{ fullName: { _ilike: $fullName } }, { email: { _ilike: $fullName } }, { phone: { _eq: $phone } }] }
        { stage: { _in: $stages } }
        { status: { _in: $urgency } }
        { locationID: { _in: $locationIDs } }
      ]
      status: { _nin: $discordStatus }
    }
    limit: $limit
    offset: $offset
  ) {
    partnerLocationID
    partnerID
    firstName
    lastName
    phone
    email
    location
    locationID
    date
    stage
    step
    detail
    assignedOS
    createdBy
    specialistID
    departmentMangerID
    accountManagerID
    alertTime
    status
  }
}
`;
export const ONBOARDING_CANDIDATE_VIEW_TOTAL = gql`
  query OnboardingCandidateViewTotal($locationIDs: [uuid!], $userID: uuid!, $stages: [String!], $urgency: [String!], $fullName: String, $phone: String, $discordStatus: [String!], $paramargs:uuid!) {
    total: f_onboardingcandidateview_aggregate(
      args: {param: $paramargs}
      where: {
        _and: [
          { _or: [{ specialistID: { _eq: $userID } }, { departmentMangerID: { _eq: $userID } }, { accountManagerID: { _eq: $userID } }] }
          { _or: [{ fullName: { _ilike: $fullName } }, { email: { _ilike: $fullName } }, { phone: { _eq: $phone } }] }
          { stage: { _in: $stages } }
          { status: { _in: $urgency } }
          { locationID: { _in: $locationIDs } }
        ]
        status: { _nin: $discordStatus }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const QUERY_FIND_CANDIDATES_FOR_USER = gql`
  query findCandidate($candidateID: uuid!) {
    candidate: clientLocationPartners(where: { id: { _eq: $candidateID } }) {
      id
      approvalDate
      notes
      notesList
      partner {
        id
        userID
        firstName
        lastName
        fullName
        email
        phone
        insuranceExpiry
        ids(where: { idType: { _eq: CHECKR } }) {
          id
          idType
          externalID
          partnerID
        }
        user {
          id
          username
        }
      }
      location {
        id
        name
        stageCounts {
          count
          stage
          weekly
        }
        headCountRequests {
          id
          count
          approved
          startDate
        }
        teamSpecialist {
          id
          employee {
            id
            firstName
            lastName
            email
          }
        }
        client {
          id
        }
      }
      candidateProgress(
        limit: 1
        order_by: { date: desc }
        where: {
          _and: [
            { candidateStatus: { _neq: "COMPLETE" } }
            # { candidateStatus: { _in: $urgency } }
            # { step: { stage: {_in: $stages } } }
          ]
        }
      ) {
        step {
          name
          description
          stage
          timeBasedAlerts {
            id
            status
            time
          }
        }
        id
        candidateStatus
        candidateStatusDetail
        date
        alertTime
      }
    }
  }
`;

export const FIND_CANDIDATE_STEPS = gql`
  subscription findFunnelSteps($candidateID: uuid!) {
    candidateProgress(where: { candidateID: { _eq: $candidateID } }, order_by: { date: desc }) {
      id
      step {
        name
        description
        stage
      }
      candidateStatus
      candidateStatusDetail
      date
      communications {
        communicationType
        date
      }
    }
  }
`;

export const FIND_PARTNER_APPLICATION = gql`
  query findPartnerApplication($candidateID: uuid!) {
    partnerApplications(where: { locationPartnerID: { _eq: $candidateID } }) {
      id
      currentStep
      status
      responses {
        questionID
        response
      }
    }
  }
`;

export const FIND_PARTNERS_FOR_USER = gql`
  subscription findPartnersForUser($userID: uuid!, $offset: Int, $limit: Int) {
    partners(
      where: {
        _or: [{ locations: { location: { teamSpecialist: { employee: { userID: { _eq: $userID } } } } } }, { locations: { location: { team: { accountManager: { userID: { _eq: $userID } } } } } }]
      }
      order_by: { created_at: desc_nulls_last }
      offset: $offset
      limit: $limit
    ) {
      id
      firstName
      lastName
      email
      phone
      locations {
        id
        candidateProgress(order_by: { date: desc }, where: { _and: [{ candidateStatus: { _eq: "COMPLETE" } }, { candidateStep: { _eq: "DOCUMENTS" } }] }) {
          id
          step {
            name
            description
            stage
            timeBasedAlerts {
              id
              status
              time
            }
          }
          candidateStatus
          candidateStatusDetail
          date
          alertTime
        }
        location {
          id
          name
          status
          teamSpecialist {
            id
            employee {
              id
              firstName
              lastName
              email
              userID
            }
          }
        }
      }
    }
  }
`;

export const ONBOARDING_PARTNER_VIEW = gql`
query f_onboardingpartnerviewv2($userID: uuid!, $location: [uuid!], $status: [String!], $driverLicence: date, $insuranceExpiry: date, $offset: Int, $limit: Int, $fullName: String, $phone: String, $lastWeekStartDate: date, $lastWeekEndDate: date,$paramargs:uuid!) {
  total: f_onboardingpartnerviewv2_aggregate(where: {_and: [{_or: [{fullName: {_ilike: $fullName}}, {email: {_ilike: $fullName}}, {phone: {_eq: $phone}}]}, {_or: [{specialistID: {_eq: $userID}}, {accountManagerID: {_eq: $userID}}, {departmentManagerID: {_eq: $userID}}]}, {_and: [{approvalDate: {_is_null: false}}, {locationID: {_in: $location}}, {DLExpiration: {_lte: $driverLicence}}, {insuranceExpiry: {_lte: $insuranceExpiry}}]}, {partnerStatus: {_in: $status}}, {approvalDate: {_gte: $lastWeekStartDate}}, {approvalDate: {_lte: $lastWeekEndDate}}]}, args: {param: $paramargs}) {
    aggregate {
      count
    }
  }
  onboardingPartnerView:f_onboardingpartnerviewv2(where: {_and: [{_or: [{fullName: {_ilike: $fullName}}, {email: {_ilike: $fullName}}, {phone: {_eq: $phone}}]}, {_or: [{specialistID: {_eq: $userID}}, {accountManagerID: {_eq: $userID}}, {departmentManagerID: {_eq: $userID}}]}, {_and: [{approvalDate: {_is_null: false}}, {locationID: {_in: $location}}, {DLExpiration: {_lte: $driverLicence}}, {insuranceExpiry: {_lte: $insuranceExpiry}}]}, {partnerStatus: {_in: $status}}, {approvalDate: {_gte: $lastWeekStartDate}}, {approvalDate: {_lte: $lastWeekEndDate}}]}, offset: $offset, limit: $limit, distinct_on: partnerID, args: {param: $paramargs}) {
    partnerLocationID
    partnerID
    userID
    firstName
    lastName
    phone
    email
    notes
    insuranceExpiry
    lastPay
    partnerStatus
    externalID
    approvalDate
    DLExpiration
    locationName
    locationID
    specialistID
    specialistName
    accountManagerID
    accountManagerName
    departmentManagerID
    departmentManagerName
    partneresult
  }
}

`;

export const QUERY_FIND_PARTNERS_FOR_USER_V2 = gql`
  query findPartnersForUserV2($id: uuid!) {
    partners: clientLocationPartners(where: { id: { _eq: $id } }) {
      id
      notesList
      notes
      approvalDate
      partnerCBCResult
      status
      location {
        id
        name
        client {
          id
          name
        }
      }
      partner {
        id
        userID
        firstName
        lastName
        email
        phone
        insuranceExpiry
        driversLicenses(order_by: { expiration: desc }) {
          id
          expiration
          state
          number
        }
        payouts(order_by: { endDate: desc }) {
          id
          endDate
        }
        ids(where: { idType: { _eq: CHECKR } }) {
          id
          idType
          externalID
          partnerID
        }
      }
      candidateProgress(order_by: { date: desc }, where: { _and: [{ candidateStatus: { _eq: "COMPLETE" } }, { candidateStep: { _eq: "DOCUMENTS" } }] }) {
        id
        step {
          name
          description
          stage
          timeBasedAlerts {
            id
            status
            time
          }
        }
        candidateStatus
        candidateStatusDetail
        date
        alertTime
      }
    }
  }
`;
export const QUERY_FIND_PARTNER = gql`
  query FindPartner($partnerID: uuid!) {
    partner: clientLocationPartners(where: { partnerID: { _eq: $partnerID } }) {
      id
      notes
      approvalDate
      status
      location {
        id
        name
        client {
          id
          name
        }
      }
      partner {
        id
        userID
        firstName
        lastName
        email
        phone
        insuranceExpiry
        driversLicenses(order_by: { expiration: desc }) {
          id
          expiration
          state
          number
        }
        payouts(order_by: { endDate: desc }) {
          id
          endDate
        }
        ids(where: { idType: { _eq: CHECKR } }) {
          id
          idType
          externalID
          partnerID
        }
      }
      candidateProgress(order_by: { date: desc }, where: { _and: [{ candidateStatus: { _eq: "COMPLETE" } }, { candidateStep: { _eq: "DOCUMENTS" } }] }) {
        id
        step {
          name
          description
          stage
          timeBasedAlerts {
            id
            status
            time
          }
        }
        candidateStatus
        candidateStatusDetail
        date
        alertTime
      }
    }
  }
`;
export const QUERY_FIND_PARTNERS_FOR_USER = gql`
  query findPartnersForUser($userID: uuid!, $location: [uuid!], $offset: Int, $limit: Int) {
    total: partners_aggregate(
      where: {
        _or: [{ locations: { location: { teamSpecialist: { employee: { userID: { _eq: $userID } } } } } }, { locations: { location: { team: { accountManager: { userID: { _eq: $userID } } } } } }]
        _and: { locations: { _and: [{ approvalDate: { _is_null: false } }, { clientLocationID: { _in: $location } }] } }
      }
    ) {
      aggregate {
        count
      }
    }
    partners(
      where: {
        _or: [{ locations: { location: { teamSpecialist: { employee: { userID: { _eq: $userID } } } } } }, { locations: { location: { team: { accountManager: { userID: { _eq: $userID } } } } } }]
        _and: { locations: { _and: [{ approvalDate: { _is_null: false } }, { clientLocationID: { _in: $location } }] } }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      firstName
      lastName
      email
      phone
      locations {
        id
        approvalDate
        candidateProgress(order_by: { date: desc }, where: { _and: [{ candidateStatus: { _eq: "COMPLETE" } }, { candidateStep: { _eq: "DOCUMENTS" } }] }) {
          id
          step {
            name
            description
            stage
            timeBasedAlerts {
              id
              status
              time
            }
          }
          candidateStatus
          candidateStatusDetail
          date
          alertTime
        }
        location {
          id
          name
          status
          teamSpecialist {
            id
            employee {
              id
              firstName
              lastName
              email
              userID
            }
          }
          client {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PARTNER_FILES = gql`
  subscription getPartnerFiles($id: uuid!, $locationID: uuid!) {
    funnelFiles(where: { locationFunnel: { clientLocationID: { _eq: $locationID } } }) {
      id
      label
      partnerFiles(where: { locationPartnerID: { _eq: $id } }, order_by: { file: { updated_at: desc } }) {
        id
        funnelFilesID
        file {
          id
          name
        }
      }
    }
  }
`;

export const GET_CANDIDATE_DETAILS = gql`
  subscription getCandidateDetails($id: uuid!) {
    candidateDetails: clientLocationPartners_by_pk(id: $id) {
      id
      partner {
        user {
          id
        }
        id
        dateOfBirth
        ssn
        ein
        ssnLastFour
        einLastFour
        driversLicenses {
          id
          number
          expiration
          state
        }
        insuranceExpiry
        address {
          id
          address1
          city
          state
          postalCode
        }
        criminalHistory {
          id
          wasConvicted
          convictionDescription
          hasPendingCharges
          pendingChargesDescription
          isSexOffender
          stateRegistered
          stateRegisteredYear
          countyRegistered
        }
      }
    }
  }
`;

export const GET_CHECKR_REPORTS_CONFIG = gql`
  query getCheckrReportsConfig {
    systemConfig(where: { key: { _eq: "checkrReportsUri" } }) {
      value
    }
  }
`;

export const GET_CBC_DATE = gql`
  query GetCBCDate($externalID: String) {
    partnerBackgroundReports(where: { externalID: { _eq: $externalID } }, order_by: { reportDate: desc }) {
      reportDate
      id
    }
  }
`;
export const GET_CHECKR_EXPIRY = gql`
  query getCheckerExpiry($externalId: String) {
    partnerBackgroundReports(order_by: { reportDate: desc_nulls_last }, where: { externalID: { _eq: $externalId } }) {
      id
      reportDate
    }
  }
`;

export const GET_INSURANCE_EXPIRY = gql`
  subscription getInsuranceExpiry($partnerID: uuid) {
    partners(where: { id: { _eq: $partnerID } }) {
      id
      insuranceExpiry
    }
  }
`;
export const GET_CLIENT_NAME=gql`
query getClientName($id:uuid) {
  clients(where: {id: {_eq:$id }}) {
    name
    id
  }
}
`;
