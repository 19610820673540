import gql from "graphql-tag";

export const GET_PARTNERS = gql`
  query getPartners($offset: Int, $fullName: String) @cached(ttl: 60) {
    partners(limit: 50, offset: $offset, where: { fullName: { _ilike: $fullName } }) {
      id
      firstName
      lastName
      email
      phone
      locations {
        id
      }
    }
    partners_aggregate(where: { fullName: { _ilike: $fullName } }) {
      aggregate {
        count
      }
    }
  }
`;

// export const GET_PARTNER_SIGNED_DOCUMENTS = gql`
//   subscription GetPartnerTemplates($locationPartnerIDs: [uuid!]) {
//     signedDocuments(where: { locationPartnerID: { _in: $locationPartnerIDs } }) {
//       id
//       locationPartnerID
//       signedOn
//       created_at
//       documentTemplate {
//         id
//         title
//         locationID
//         clientID
//       }
//     }
//   }
// `;

export const GET_PARTNER_SIGNED_DOCUMENTS = gql`
  subscription GetPartnerTemplates($locationPartnerIDs: [uuid!]) {
    signedDocuments(where: { locationPartnerID: { _in: $locationPartnerIDs } }) {
      id
      locationPartnerID
      signedOn
      created_at
      documentTemplate {
        id
        title
        locationID
        clientID
      }
      file {
        id
        name
        path
      }
    }
  }
`;

export const GET_GARNISHMENTS = gql`
subscription getGarnishments($partnerID: uuid!) {
  settlementItems(where: {_and: {partnerID: {_eq: $partnerID}, settlementItemType: {_eq: GARNISHMENTS}, status: {_eq: ACTIVE}}}, order_by: {order: asc}) {
      additionalData
      billTo
      category
      chartOfAccounts
      description
      id
      name
      partnerID
      settlementItemType
      order
      status
      templateID
      transactionType
      taxSetting
      statusChangedDate
    }
  }
`;
